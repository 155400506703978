import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
// import lulc from 'assets/images/innowind-lulc-bannerimg.jpg';
import { ReactComponent as DotIcon } from '@mike/mike-shared-frontend/media/icons/ColorCircle.svg';
import ReactCompareImage from 'react-compare-image';
import lulcImgData from 'assets/images/banner_lulc_data.jpg';
import lulcImgSat from 'assets/images/banner_lulc_sat.jpg';

const bulletPoints = [
  'Land cover classes tailored each site depending on client needs',
  'High accuracy using latest machine learning algorithms',
  'Near real time processing of the latest satellite imagery to ensure up-to-date site status.',
  'Grid size 10-30 meters',
  'Available from 1986 onwards',
  'Automated and fast global delivery for any time and site requested',
];

const LULC = ({ bg, refLulc }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={bg} ref={refLulc}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'h4'} className={landingpage.h4}>
              Land cover tailored for wind sites anywhere in the World
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              Coarse land cover data can lead to less accurate wind assessments.
            </Typography>
            <Box py={1}></Box>
            <Typography variant={'body1'}>
              We use the latest machine learning technology to create accurate
              land cover maps in high resolution for any site on the planet.
            </Typography>
            <Box py={1}></Box>
            <Typography variant={'body1'}>
              Our land cover data Is processed fast and automated to ensure
              consistent, reliable and timely delivery.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {bulletPoints.map((bulletPoint, i) => (
              <Grid container spacing={0} key={i}>
                <Grid item xs={1}>
                  <DotIcon className={landingpage.icon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={'body1'} className={landingpage.detail}>
                    {bulletPoint}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <ReactCompareImage
              leftImage={lulcImgSat}
              rightImage={lulcImgData}
              handleSize={24}
              sliderPositionPercentage={0.125}
            />
            <Typography variant={'body2'} className={landingpage.caption}>
              Land cover
            </Typography>
            {/* <BeforeAfterSlider
              before={lulcImgData}
              after={lulcImgSat}
              height={180}
            /> */}
            {/* <img src={lulc} style={{ width: '100%' }} alt={'classification'} /> */}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default LULC;
