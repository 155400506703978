import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import DHILogo from 'assets/images/dhi_logo_white.png';

const linkStyle = makeStyles({
  logo: {
    opacity: 0.8,
    transition: 'opacity .2s',
    '&:hover': {
      opacity: 1,
    },
  },
});

const Navbar = () => {
  const classes = linkStyle();

  return (
    <Grid
      container
      spacing={0}
      style={{ backgroundColor: '#00A4EC', padding: '8px 32px' }}
    >
      <Grid item xs={6} sm={2}>
        <a
          href="https://www.dhi-gras.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.logo}
        >
          <img src={DHILogo} alt="DHI Logo" />
        </a>
      </Grid>

      <Grid item xs={6} sm={10}>
        {/* <Grid
          container
          alignItems="flex-end"
          justify="flex-end"
          direction="row"
        >
          <Link href="http://labs.dhi-gras.com/dkfloods/" style={linkStyle}>
            Se oversvømmelseskortet
          </Link>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Navbar;
