import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { landingpageStyle } from 'styles/landingpage';
// import BG from 'assets/images/innowind-banner-poster-v0.jpg';
import windmillImg from 'assets/images/banner_img2.jpg';

// https://www.shutterstock.com/da/image-photo/scenic-wind-farm-westcoast-estonia-1434618383
// https://www.shutterstock.com/da/image-photo/aerial-view-windmills-green-summer-forest-1472488970

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    background: `linear-gradient(45deg, rgba(11, 69, 102, 0.6), rgba(0, 164, 236, 0.5)), url(${windmillImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'left center',
  },
  description: {
    color: '#fff',
    fontSize: 24,
    fontWeight: 700,
  },
  title: {
    fontSize: 240,
    lineHeight: 0,
    fontWeight: 'bold',
    color: 'rgba(0, 164, 236, 0.75)',
  },
});

const Banner = ({ bg }) => {
  const classes = useStyles();
  const landingpage = landingpageStyle();

  return (
    <div className={classes.root}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={0} style={{ padding: '20vh 0' }}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title}>WindSight</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.description}>
              First class input data for wind models to ensure optimal wind
              resource estimations
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Banner;
