import React from 'react';

import About from './partials/About';
import Banner from './partials/Banner';
import Contact from './partials/Contact';
import ContactForm from './partials/ContactForm';
import DEM from './partials/DEM';
import Forest from './partials/Forest';
import HowItWorks from './partials/HowItWorks';
import LULC from './partials/LULC';
import Navbar from './partials/Navbar';
import Partners from './partials/Partners';
import ProductOverview from './partials/ProductOverview';
import Roughness from './partials/Roughness';
import Testimonial from './Testimonial';
import TrendAnalysis from './partials/TrendAnalysis';
// import Webinar from './partials/Webinar';
import What from './partials/What';

import formatISO from 'date-fns/formatISO';
import * as Sentry from '@sentry/browser';
import { sendContactInformation } from 'data';
import { landingpageStyle } from 'styles/landingpage';
import { Fab } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

const LandingPage = () => {
  console.log('# react app environment:', process.env.NODE_ENV);
  const [loading, setLoading] = React.useState(false);
  const [contactConfirmed, setConfirmed] = React.useState();

  const refLulc = React.createRef();
  const refForest = React.createRef();
  const refTrends = React.createRef();
  const refDEM = React.createRef();
  const refRoughness = React.createRef();

  const refs = [refLulc, refForest, refTrends, refDEM, refRoughness];
  const landingpage = landingpageStyle();

  const scroll = ref =>
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });

  const handleSendMail = async details => {
    try {
      setLoading(true);
      const response = await sendContactInformation({
        baseUrl: 'mail',
        body: {
          application: 'windsight',
          country: details.country,
          email: details.email,
          first_name: details.firstname,
          last_name: details.lastname,
          message: details.message,
          request_date: formatISO(new Date(Date.now())),
        },
      });
      // await sendContactInformation({
      //   baseUrl: 'lead',
      //   body: {
      //     companyname: details.companyname,
      //     emailaddress1: details.email,
      //     firstname: details.firstname,
      //     lastname: details.lastname,
      //     dhi_commentfromweb: {
      //       country: details.country,
      //       message: details.message,
      //     },
      //     subject: 'WindSight Lead',
      //   },
      // });
      if (!response) throw Error(`Request rejected`);
      setLoading(false);
      setConfirmed(true);
    } catch (error) {
      setLoading(false);
      setConfirmed(false);
      Sentry.captureMessage(error);
      console.error(error);
    }
  };

  return (
    <>
      <Navbar />
      <Banner />
      {/* <Webinar /> */}
      <What bg={landingpage.root} />
      <Testimonial bg={landingpage.rootGrey} />
      <HowItWorks bg={landingpage.root} />
      <ProductOverview
        bg={landingpage.rootGrey}
        scroll={id => scroll(id)}
        refs={refs}
      />

      <LULC refLulc={refLulc} bg={landingpage.root} />
      <Forest bg={landingpage.rootGrey} refForest={refForest} />
      <TrendAnalysis bg={landingpage.root} refTrends={refTrends} />
      <DEM bg={landingpage.rootGrey} refDEM={refDEM} />
      <Roughness bg={landingpage.root} refRoughness={refRoughness} />

      <ContactForm
        bg={landingpage.rootGrey}
        contactConfirmed={contactConfirmed}
        handleSendMail={handleSendMail}
        loading={loading}
        setLoading={setLoading}
      />
      <Partners bg={landingpage.root} />
      <About bg={landingpage.rootGrey} />
      <Contact />
      <Fab
        href="#contact-form"
        color="secondary"
        size="medium"
        aria-label="contact"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
        }}
      >
        <ContactSupportIcon style={{ width: 25, height: 25 }} />
      </Fab>
    </>
  );
};

export default LandingPage;
