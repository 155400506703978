import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
// import demImg from 'assets/images/innowind-dem-bannerimg.jpg';
import { ReactComponent as DotIcon } from '@mike/mike-shared-frontend/media/icons/ColorCircle.svg';
import ReactCompareImage from 'react-compare-image';
import demImgData from 'assets/images/banner_dem_data.jpg';
import demImgSat from 'assets/images/banner_dem_sat.jpg';

const bulletPoints = [
  'Elevation and topography up to 50cm resolution',
  'Very cost-efficient compared to LIDAR campaigns',
  'Fast delivery',
  'Combine surface terrain model with surface model to get obstacle heights including forest heights.',
];

const DEM = ({ bg, refDEM }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={bg} ref={refDEM}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'h4'} className={landingpage.h4}>
              Very high-resolution elevation layers
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              Wind modelling in regions with complex terrain can be tricky and
              often requires finer detailed elevation data.
            </Typography>
            <Box py={1} />
            <Typography variant={'body1'}>
              We offer cost-efficient elevation data with up to 50 cm pixel
              resolution that are comparable in quality to expensive LIDAR
              campaigns.
            </Typography>
            <Box py={1} />

            <Typography variant={'body1'}>
              Drawing from a rich archive of data we provide fast delivery of
              both terrain and surface models. If ordering both terrain and
              surface models, we can also derive feature heights (e.g. buildings
              or forests) - leading to the combined benefit of improved digital
              terrain and superior estimates of roughness length when using
              advanced surface roughness models.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {bulletPoints.map((bulletPoint, i) => (
              <Grid container spacing={0} key={i}>
                <Grid item xs={1}>
                  <DotIcon className={landingpage.icon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={'body1'} className={landingpage.detail}>
                    {bulletPoint}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <ReactCompareImage
              leftImage={demImgSat}
              rightImage={demImgData}
              handleSize={24}
              sliderPositionPercentage={0.125}
            />
            <Typography variant={'body2'} className={landingpage.caption}>
              Digital Elevation
            </Typography>
            {/* <img
              src={demImg}
              style={{ width: '100%' }}
              alt={'digital elevation model'}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default DEM;
