import React from 'react';
import ReactDOM from 'react-dom';
import IEnotification from './containers/IEnotification';
import LandingPage from './containers/Landingpage';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from '@material-ui/core';
import DHITheme from './styles/theme';

import 'typeface-roboto';
import './index.css';

process.env.NODE_ENV === 'proudction' &&
  Sentry.init({
    dsn:
      'https://c23dbdc093fe47ecad0c8600eb5bf5bc@o377546.ingest.sentry.io/5272526',
  });

const isIE = /*@cc_on!@*/ false || !!document.documentMode;

ReactDOM.render(
  <>
    {isIE ? (
      <IEnotification />
    ) : (
      <ThemeProvider theme={DHITheme}>
        <LandingPage />
      </ThemeProvider>
    )}
  </>,

  document.getElementById('root')
);
