import React from 'react';
import ProductCard from './ProductCard';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';

import lulc from 'assets/images/innowind-lulc-bannerimg.jpg';
import forestHeightImg from 'assets/images/innowind-forestheight-bannerimg.jpg';
import trendsImg from 'assets/images/innowind-trends-bannerimg.jpg';
import demImg from 'assets/images/innowind-dem-bannerimg.jpg';
import roughnessImg from 'assets/images/innowind-roughness-bannerimg.jpg';

const ProductOverview = ({ bg, scroll, refs }) => {
  const landingpage = landingpageStyle();

  const cardDetails = [
    {
      title: 'Land cover for wind sites',
      image: lulc,
    },
    {
      title: 'Forest data',
      image: forestHeightImg,
    },
    {
      title: 'Historical and future changes',
      image: trendsImg,
    },
    {
      title: 'Very high-resolution elevation layers',
      image: demImg,
    },
    {
      title: 'Advanced surface roughness models',
      image: roughnessImg,
    },
  ];

  return (
    <Paper className={bg}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12}>
            <Typography
              variant={'h4'}
              style={{ textAlign: 'center' }}
              className={landingpage.h4}
            >
              WindSight includes 5 high quality data layers
            </Typography>
          </Grid>
          <Grid item xs={false} sm={1} />
          <Grid item xs={12} sm={10}>
            <Typography variant={'body1'} style={{ textAlign: 'center' }}>
              Our portfolio of 5 different data products provides high-quality
              information about land cover, historical and future changes,
              topography, key forest characteristics and surface roughness.
            </Typography>
          </Grid>

          {cardDetails &&
            cardDetails.map((cardDetail, i) =>
              i < 4 ? (
                <Grid item xs={12} sm={6} key={i}>
                  <ProductCard
                    props={cardDetail}
                    refs={refs[i]}
                    scroll={scroll}
                  />
                </Grid>
              ) : (
                <React.Fragment key={i}>
                  <Grid item xs={12} sm={3}></Grid>
                  <Grid item xs={12} sm={6} key={i}>
                    <ProductCard
                      props={cardDetail}
                      refs={refs[i]}
                      scroll={scroll}
                    />
                  </Grid>
                </React.Fragment>
              )
            )}

          <Grid item xs={12} sm={1}></Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ProductOverview;
