import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';

const About = ({ bg }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={bg}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'h4'} className={landingpage.h4}>
              About DHI GRAS
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              DHI GRAS is specialized in satellite image and data processing.
              DHI GRAS handles the entire data flow from the reception and
              processing of satellite images to the delivery of the requested
              final information products.
            </Typography>
            <Box py={1} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              The company was established in 2000 and has completed projects and
              delivered services in over 75 countries worldwide for a range of
              different clients.
            </Typography>
            <Box py={1} />

            <Typography variant={'body1'}>
              For more information about DHI GRAS please visit{' '}
              <a
                href="https://www.dhi-gras.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={landingpage.link}
              >
                https://www.dhi-gras.com/
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default About;
