import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { ReactComponent as DotIcon } from '@mike/mike-shared-frontend/media/icons/ColorCircle.svg';
import { landingpageStyle } from 'styles/landingpage';
// import roughnessImg from 'assets/images/innowind-roughness-bannerimg.jpg';
import ReactCompareImage from 'react-compare-image';
import roughnessImgData from 'assets/images/banner_roughness_data.jpg';
import roughnessImgSat from 'assets/images/banner_roughness_sat.jpg';

const bulletPoints = [
  'Conversion of LAI, Land Cover and Forest Height into surface roughness',
  'Using state of the art: ORA and Raupach models',
  'Our advanced roughness length layers are compatible as input for a wide range softwares and modelling methods including windPRO, WaSP and CFD tools.',
];

const TrendAnalysis = ({ bg, refRoughness }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={bg} ref={refRoughness}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'h4'} className={landingpage.h4}>
              Advanced surface roughness models for forested areas
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              We use state-of-the-art models for estimating roughness length on
              a 20-meter grid.
            </Typography>
            <Box py={1} />

            <Typography variant={'body1'}>
              We offer two roughness length products based on 1) the Objective
              Roughness Approach (ORA) and 2) equations from the Raupach method.
            </Typography>
            <Box py={1} />

            <Typography variant={'body1'}>
              The ORA method uses forest height data alone, while Raupach
              considers both forest height and density data.
            </Typography>
            <Box py={1} />

            <Typography variant={'body1'}>
              These models have been tested in several sites globally in the
              InnoWind project and have shown significant improvements in wind
              flow modelling especially in forested sites, when compared to
              using conventional land cover maps and look-up tables.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {bulletPoints.map((bulletPoint, i) => (
              <Grid container spacing={0} key={i}>
                <Grid item xs={1}>
                  <DotIcon className={landingpage.icon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={'body1'} className={landingpage.detail}>
                    {bulletPoint}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <ReactCompareImage
              leftImage={roughnessImgSat}
              rightImage={roughnessImgData}
              handleSize={24}
              sliderPositionPercentage={0.125}
            />
            <Typography variant={'body2'} className={landingpage.caption}>
              Surface roughness
            </Typography>
            {/* <img
              src={roughnessImg}
              style={{ width: '100%' }}
              alt={'trend analysis'}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default TrendAnalysis;
