import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { landingpageStyle } from 'styles/landingpage';
// import whatImg from 'assets/images/innowind-landingpage-what-v0.jpg';
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
});

const What = () => {
  const classes = useStyles();
  const landingpage = landingpageStyle();

  return (
    <Paper className={classes.root}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12} sm={6}>
            <Box py={1}>
              <Typography variant={'h4'} className={landingpage.h4}>
                What is WindSight
              </Typography>
            </Box>
            <Box py={1}>
              <Typography variant={'body1'}>
                The worldwide demand for wind solutions is growing. This gives
                the wind industry new opportunities but also increased
                competition.
              </Typography>
            </Box>
            <Box py={1}>
              <Typography variant={'body1'}>
                WindSight is all about providing the right input data to lower
                the error of the wind resource estimations.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.videoWrapper}>
              <iframe
                src="https://player.vimeo.com/video/427313739?title=0&byline=0&portrait=0"
                width="560"
                height="315"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="dhi windsight"
                className={classes.video}
              >
                >
              </iframe>
            </div>

            {/* <img
              src={whatImg}
              alt="roughness"
              style={{ width: 540, height: 320 }}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default What;
