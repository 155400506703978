import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import ContactConfirmation from './ContactConfirmation';
import ContactFormComponent from './ContactFormComponentDynamics';
import ContactError from './ContactError';
import { Box, Typography } from '@material-ui/core';
import { css } from '@emotion/core';
import { landingpageStyle } from 'styles/landingpage';

const defaultDetails = {
  companyname: '',
  country: '',
  email: '',
  firstname: '',
  lastname: '',
  message: '',
};

const contactStyle = {
  color: '#00A4EC',
  textAlign: 'center',
};

const override = css`
  margin: 0 auto;
`;

const ContactForm = ({ bg, contactConfirmed, handleSendMail, loading }) => {
  const [details, setDetails] = React.useState({ ...defaultDetails });
  const landingpage = landingpageStyle();

  const updateDetails = e => {
    let obj = details;
    obj[e.target.id] = e.target.value;
    setDetails({ ...obj });
  };

  return (
    <Box
      className={bg}
      display="flex"
      justifyContent="center"
      style={{ padding: '20vh 0' }}
      id="contact-form"
    >
      <Box
        width={400}
        p={4}
        style={{ backgroundColor: '#fff', borderRadius: 4 }}
      >
        <Box pb={2}>
          <Typography
            variant="h4"
            className={landingpage.h4}
            style={contactStyle}
          >
            Contact us for more information about WindSight
          </Typography>
        </Box>

        {loading ? (
          <Box height={240} display="flex" alignItems="center">
            <BounceLoader size={40} color={'#00A4EC'} css={override} />
          </Box>
        ) : contactConfirmed === true ? (
          <Box height={240}>
            <ContactConfirmation />
          </Box>
        ) : contactConfirmed === false ? (
          <Box height={240}>
            <ContactError />
          </Box>
        ) : (
          <Box minHeight={240}>
            <ContactFormComponent
              details={details}
              handleSendMail={handleSendMail}
              setDetails={setDetails}
              updateDetails={updateDetails}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ContactForm;
