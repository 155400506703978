import axios from 'axios';
import * as Sentry from '@sentry/browser';

const apiBaseUrls = {
  // lead: 'http://localhost:7071/api/gras-leadgeneration',
  // lead:
  //   'https://dynamics-leads-service.azurewebsites.net/api/gras-leadgeneration', //not existent anymore
  mail: 'https://dna-sendmail.azurewebsites.net/api/sendmail',
};

export const sendContactInformation = async ({ baseUrl, body }) => {
  try {
    const endpoint = apiBaseUrls[baseUrl];
    const response = await axios.post(endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    Sentry.captureMessage(error);
    return Promise.reject(error);
  }
};
