import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

const imageStyle = makeStyles({
  background: {
    border: '4px solid #F2F5F7',
    borderRadius: 4,
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: '24px 0px',
    opacity: '0.85',
    transition: 'opacity .2s',
    '&:hover': {
      opacity: 1,
    },
  },
});
const ProductCard = ({ props, refs, scroll }) => {
  //   const { title, link, image, description } = props;
  const { title, image } = props;
  const classes = imageStyle();

  return (
    <Box
      className={classes.background}
      onClick={() => scroll(refs)}
      style={{ backgroundImage: `url(${image})` }}
    >
      <Box
        style={{
          textAlign: 'center',
          backgroundColor: 'rgba(255,255,255,0.75)',
          width: '50%',
          margin: 'auto auto',
          borderRadius: 4,
        }}
      >
        <Typography variant="subtitle2">{title}</Typography>
      </Box>
    </Box>
  );
};

export default ProductCard;
