import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
// import trendsImg from 'assets/images/innowind-trends-bannerimg.jpg';
import { ReactComponent as DotIcon } from '@mike/mike-shared-frontend/media/icons/ColorCircle.svg';
import ReactCompareImage from 'react-compare-image';
import trendsImgData from 'assets/images/banner_trends_data.jpg';
import trendsImgSat from 'assets/images/banner_trends_sat.jpg';

const bulletPoints = [
  'Using time series of historic satellite imagery',
  'Shows years of when a forest was last clear cut or logged',
  'Analyze site trends and changes',
  'Analyze regrowth of forest and forest ages',
  'Qualify expected future deforestation based on historic trends',
  'Available for any site in the World',
];

const TrendAnalysis = ({ bg, refTrends }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={bg} ref={refTrends}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'h4'} className={landingpage.h4}>
              Historical and future changes on wind sites
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              Analyzing past land cover is useful for wind resource assessments
              in several ways.
            </Typography>
            <Box py={1}></Box>
            <Typography variant={'body1'}>
              Firstly, estimates of rates and locations of forest clearing or
              logging provide a powerful insight to land dynamics of each site,
              and are used to gain a better understanding of past and expected
              future changes.
            </Typography>
            <Box py={1}></Box>
            <Typography variant={'body1'}>
              Secondly, identifying exactly where and importantly when forests
              are cleared and begins re-growing can reveal details of forest
              structure and growth rates.
            </Typography>
            <Box py={1}></Box>
            <Typography variant={'body1'}>
              We use this information for improved estimates of past, current
              and future surface roughness and site conditions.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {bulletPoints.map((bulletPoint, i) => (
              <Grid container spacing={0} key={i}>
                <Grid item xs={1}>
                  <DotIcon className={landingpage.icon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={'body1'} className={landingpage.detail}>
                    {bulletPoint}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <ReactCompareImage
              leftImage={trendsImgSat}
              rightImage={trendsImgData}
              handleSize={24}
              sliderPositionPercentage={0.125}
            />
            <Typography variant={'body2'} className={landingpage.caption}>
              Forest clearing over the years
            </Typography>
            {/* <img
              src={trendsImg}
              style={{ width: '100%' }}
              alt={'trend analysis'}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default TrendAnalysis;
