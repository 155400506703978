import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';

const HowItWorks = ({ bg }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={bg}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12}>
            <Typography variant={'h4'} className={landingpage.h4}>
              Why is this important?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              Coarse land cover information and limited knowledge on forest
              heights are used to “guesstimate” surface roughness which in turn
              lead to less accurate wind performance models.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              The novelty of the WindSight data package propels a new generation
              of satellite-based information products to improve wind resource
              assessments.
            </Typography>
            <Box py={1} />
            <Typography variant={'body1'}>
              All products are compatible with WindPRO and WAsP and standard GIS
              software.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default HowItWorks;
