import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
import vestasLogo from 'assets/images/vestas.png';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const logoStyle = {
  width: '100%',
  padding: '20px 40px 0px',
  boxSizing: 'border-box',
};

const testimonials = [
  {
    logo: vestasLogo,
    person: 'Yavor Hristov, VESTAS (INNOWIND partner)',
    quote:
      '“As part INNOWIND project, Satellite derived 1m height contours were used for modelling the topography of a complex terrain site. It enabled capturing a very specific flow field feature that was measured/recorded on this complex terrain, but was not possible to reproduce using the standard SRTM 30m height-contour data.”',
  },
  {
    logo: null,
    person:
      'Rogier Floors, Senior Researcher, DTU Wind Energy (INNOWIND partner)',
    quote:
      '“DHI landcover maps provide a much more detailed picture of the terrain then commonly used landcover databases such as CORINE and result in lower errors when using the WAsP model for flow modelling.”',
  },
  {
    logo: vestasLogo,
    person: 'Mark Zagar, VESTAS (INNOWIND partner)',
    quote:
      '“The Innowind analysis of the flow modelling accuracy at 9 sites has indicated a realistic potential of using satellite data sources for estimation of the surface properties in the mainstream wind energy models.”',
  },
];

const Testimonial = ({ bg }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={bg}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 16px' }}>
          <Carousel
            dots
            autoPlay={6000}
            stopAutoPlayOnHover={true}
            infinite={true}
          >
            {testimonials &&
              testimonials.map(({ logo, person, quote }, i) => (
                <Grid container style={{ paddingBottom: 32 }} key={i}>
                  <Grid item xs={12} sm={3}>
                    {logo && <img src={logo} alt={'logo'} style={logoStyle} />}
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography
                      variant={'body1'}
                      style={{ fontStyle: 'italic' }}
                    >
                      {quote}
                    </Typography>

                    <Box mt={1}>
                      <Typography variant={'body1'}>{person}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              ))}
          </Carousel>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Testimonial;
