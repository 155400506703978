import { makeStyles } from '@material-ui/core/styles';

export const landingpageStyle = makeStyles({
  landingPage: {
    maxWidth: 1080,
    padding: '20vh, 0',
    media: {
      width: 540,
      height: 320,
    },
  },
  box: {
    maxWidth: 1080,
    backgroundColor: 'transparent',
  },
  h4: {
    fontSize: 24,
    fontWeight: 700,
    color: '#0B4566',
  },
  icon: {
    height: 24,
  },
  rootGrey: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: '#F2F5F7 !important',
  },
  caption: {
    fontSize: 12,
    fontStyle: 'italic',
    textAlign: 'right',
  },
  link: {
    color: '#00A4EC',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
