import React from 'react';
import { Box, Grid, Link, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    background: theme.palette.primary.main,
  },
  box: {
    maxWidth: theme.landingPage.maxWidth - 200,
    backgroundColor: 'transparent',
  },
  text: {
    fontSize: 12,
    color: '#fff',
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box m="auto" p={2} className={classes.box}>
        <Grid container spacing={4} style={{ padding: '5vh 0' }}>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.text} style={{ fontWeight: 700 }}>
              <Link
                href="https://www.dhi-gras.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#fff' }}
              >
                DHI GRAS
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.text}>gras@dhigroup.com</Typography>
            <Typography className={classes.text}>+45 4516 9100</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.text}>Agern Alle 5,</Typography>
            <Typography className={classes.text}>2970 Hørsholm,</Typography>
            <Typography className={classes.text}>Denmark</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography className={classes.text}>CVR: 25621646</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default About;
