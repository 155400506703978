import React from 'react';
import { Box, Grid, Typography, Paper, makeStyles } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
import vestasLogo from 'assets/images/vestas.png';
import vattenfallLogo from 'assets/images/vattenfall.png';
import emdLogo from 'assets/images/emd.png';
import dtuLogo from 'assets/images/dtu.png';
import dhiLogo from 'assets/images/dhi_gras_logo_transparent.png';
import eShapeLogo from "assets/images/eshape.png"
const localStyle = makeStyles({
  logo: {
    padding: ' 20px 20px 0px',
    maxWidth: '100% ',
    boxSizing: 'border-box',
    '@media (max-width:600px)': {
      padding: '5% 10% 0px',
    },
  },
});

const logoWrapperStyle = {
  margin: 'auto',
};

const Partners = ({ bg }) => {
  const landingpage = landingpageStyle();
  const classes = localStyle();

  return (
    <Paper className={bg}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'h4'} className={landingpage.h4}>
              Origin of WindSight
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'body1'}>
              The services and products of WindSight were developed by DHI GRAS
              during{' '}
              <a
                href="https://www.innowind.dk/"
                target="_blank"
                rel="noopener noreferrer"
                className={landingpage.link}
              >
                Innowind
              </a>
              : an Innovation Fund Denmark partnership from 2017-2020 with
              VESTAS, Vattenfall, EMD and DTU that aimed to improve the accuracy
              and coverage of map layers that are used as input to
              state-of-the-art flow models
            </Typography>
          </Grid>
          <Box display="flex" justifyContent={"center"}></Box>
          <Grid item xs={6} sm={2} style={logoWrapperStyle}>
            <img src={vestasLogo} alt={'vestas'} className={classes.logo} />
          </Grid>
          <Grid item xs={6} sm={2} style={logoWrapperStyle}>
            <img
              src={vattenfallLogo}
              alt={'vattenfall'}
              className={classes.logo}
            />
          </Grid>
          <Grid item xs={6} sm={2} style={logoWrapperStyle}>
            <a href="https://e-shape.eu/">
              <img
                src={eShapeLogo}
                alt={'E-shape'}
                className={classes.logo}
              />
            </a>
          </Grid>
          <Grid item xs={6} sm={2} style={logoWrapperStyle}>
            <img src={dhiLogo} alt={'dhi gras'} className={classes.logo} />
          </Grid>
          <Grid item xs={6} sm={2} style={logoWrapperStyle}>
            <img src={emdLogo} alt={'emd'} className={classes.logo} />
          </Grid>
          <Grid item xs={6} sm={2} style={logoWrapperStyle}>
            <img src={dtuLogo} alt={'dtu'} className={classes.logo} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'body1'}>
              The <a className={landingpage.link} href="https://e-shape.eu/">e-shape</a> project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement 820852.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Partners;
