import React from 'react';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
// import forestHeightImg from 'assets/images/innowind-forestheight-bannerimg.jpg';
import { ReactComponent as DotIcon } from '@mike/mike-shared-frontend/media/icons/ColorCircle.svg';
import heightImgData from 'assets/images/banner_forest_height_data.jpg';
import heightImgSat from 'assets/images/banner_forest_height_sat.jpg';
import densityImgData from 'assets/images/banner_forest_density_data3.jpg';
import densityImgSat from 'assets/images/banner_forest_density_sat3.jpg';
import ReactCompareImage from 'react-compare-image';

const bulletPoints = [
  'Forest height - low cost and fast delivery in grid sizes of 20m',
  'Forest density – Essential for understanding forest structure impacts on wind speed',
  'Gap filling or update of existing client forest height data',
  'Forest products available at different points in time to match time of wind measurements',
];

const Forest = ({ bg, refForest }) => {
  const landingpage = landingpageStyle();

  return (
    <Paper className={bg} ref={refForest}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '20vh 0' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography variant={'h4'} className={landingpage.h4}>
              Forest data
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'body1'}>
              Forested sites represent 75% of all new land-based wind energy
              developments. However, modelling wind flow in forested sites is
              challenging, as the land surface us more dynamic and rarely
              homogeneous, often containing forest edges and changes in
              roughness that can produce sharp gradients in the flow.
            </Typography>
            <Box py={1} />
            <Typography variant={'body1'}>
              We offer several satellite derived products that lead to improved
              roughness length estimates and wind resource assessments. For
              example, our forest height and forest density layers are readily
              used in advanced roughness length models (e.g. ORA or Raupach).
              Using historical image analysis, we can provide these data for
              specific years to better match recorded wind measurements.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {bulletPoints.map((bulletPoint, i) => (
              <Grid container spacing={0} key={i}>
                <Grid item xs={1}>
                  <DotIcon className={landingpage.icon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant={'body1'} className={landingpage.detail}>
                    {bulletPoint}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactCompareImage
              leftImage={heightImgSat}
              rightImage={heightImgData}
              handleSize={24}
              sliderPositionPercentage={0.1}
            />
            <Typography variant={'body2'} className={landingpage.caption}>
              Forest Height
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReactCompareImage
              leftImage={densityImgSat}
              rightImage={densityImgData}
              handleSize={24}
              sliderPositionPercentage={0.1}
            />
            <Typography variant={'body2'} className={landingpage.caption}>
              Forest Density
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <img
              src={forestHeightImg}
              style={{ width: '100%' }}
              alt={'Forest height'}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Forest;
